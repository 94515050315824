import { Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { format as formatDate } from 'date-fns'
import memoize from 'memoizee'
import { Cell } from 'react-table'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { SparkLine } from 'components/SparkLine'
import { DataTable } from 'components/Table'

import { formatLoggerSerial } from 'lib/formatLoggerSerial'
import { deleteLogger } from 'lib/mutations/delete/deleteLogger'
import { updateLoggerForm } from 'lib/mutations/update/updateLogger'

import type { Logger } from 'types/Logger'
import type { KeysOf } from 'types/common'

type LoggerCell = Cell & {
  row: {
    original: Logger
  }
}

interface LoggersTableProps {
  data: any
  globalFilter: any
  projection: LoggersTableProjection
  download: any
}

export const LoggersTable = ({
  data = [],
  globalFilter,
  projection = 'default',
  download,
}: LoggersTableProps) => {
  const zoom = useBreakpointValue({
    base: 0.9,
    md  : 1,
  })

  return (
    <>
      {data && data?.length > 0 ? (
        <DataTable
          columns={projections[projection]}
          data={data}
          initialGlobalFilter={globalFilter}
          hiddenColumns={hiddenColumns}
          hideGlobalFilter={!(data.length > 10)}
          download={download}
          downloadColumns={downloadColumns}
          sx={{
            zoom,
            mozTransform: `scale(${zoom})`,
          }}
        />
      ) : (
        <Text>no data found</Text>
      )}
    </>
  )
}

const hiddenColumns: string[] = []
const downloadColumns: string[] = [
  'id',
  'app',
  'configuration',
  'mounting_field',
  'ports',
  'serial.id',
  'serial.model',
  'cloudloop.subscriber.contract.networkStatus',
  'modem.imei',
  'plan',
  'mongoExtras.lastReading.ts.email',
  'mongoExtras.lastReading.msgSize',
  'mongoExtras.lastReading.logger.battery',
  'extras.readingErrors',
  'mongoExtras.currentUse.org.id',
  'mongoExtras.currentUse.org.name',
  'mongoExtras.currentUse.project.id',
  'mongoExtras.currentUse.project.name',
  'mongoExtras.currentUse.project.ts.expired',
]

const columns = {
  id: {
    Header  : 'id',
    accessor: 'id',
  },

  active: {
    Header  : 'Active',
    accessor: '',
  },
  startDate: {
    Header  : 'Start Date',
    accessor: '',
  },
  endDate: {
    Header  : 'End Date',
    accessor: '',
  },
  renewalDate: {
    Header  : 'Renewal Date',
    accessor: '',
  },

  app: {
    Header  : 'App',
    accessor: 'app',
  },

  adminApp: {
    Header  : 'App',
    accessor: 'app',
  },

  configuration: {
    Header  : 'config',
    accessor: 'configuration',
  },

  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: LoggerCell) => {
      if (!value) return null

      return (
        <NotesModal
          note={value}
          name={formatLoggerSerial(row.values?.serial)}
        />
      )
    },
  },

  serial: {
    Header  : 'serial',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      return formatLoggerSerial(originalRow?.serial)
    }),
    Cell: ({ value, row }: LoggerCell) => (
      <InternalLink name={value} href={`/loggers/${row?.original?.id}`} />
    ),
  },

  serialNoLink: {
    Header  : 'serial',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      return formatLoggerSerial(originalRow?.serial)
    }),
  },

  adminSerial: {
    Header  : 'serial',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      return formatLoggerSerial(originalRow?.serial)
    }),
    Cell: ({ value, row }: LoggerCell) => {
      if (
        typeof row?.original?._filter === 'function' &&
        row.original._filter(row?.original)
      )
        return (
          <InternalLink name={value} href={`/loggers/${row?.original?.id}`} />
        )

      return (
        <Text color='red' textDecoration='line-through'>
          <InternalLink name={value} href={`/loggers/${row?.original?.id}`} />
        </Text>
      )
    },
  },

  batterySparkline: {
    Header  : 'Battery Trend',
    accessor: 'mongoExtras.batterySparkline',
    Cell    : ({ value }: LoggerCell) => {
      if (value && value.length > 0) return <SparkLine data={value} />
      return null
    },
  },

  mounting_field: {
    Header  : 'mounting field',
    accessor: 'mounting_field',
  },

  ports: {
    Header  : 'Ports',
    accessor: 'ports',
  },

  condition: {
    Header  : 'Condition',
    accessor: memoize((originalRow: any) => {
      return originalRow?.use?.[originalRow.use.length - 1]?.condition
    }),
  },

  adminCondition: {
    Header  : 'Condition',
    accessor: memoize((originalRow: any) => {
      return originalRow?.use?.[originalRow.use.length - 1]?.condition
    }),
    Cell: ({ value, row }: LoggerCell) => {
      if (
        typeof row?.original?._filter === 'function' &&
        row.original._filter(row?.original)
      )
        return <Text>{value}</Text>

      return null
    },
  },

  imei: {
    Header  : 'IMEI',
    accessor: 'modem.imei',
  },

  imeiNoLink: {
    Header  : 'IMEI',
    accessor: 'modem.imei',
  },

  adminOrg: {
    Header  : 'Org',
    accessor: memoize(
      (originalRow: any) => originalRow?.mongoExtras?.currentUse?.org?.name,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      if (value)
        return (
          <InternalLink
            name={value}
            href={`/orgs/${row?.original?.mongoExtras?.currentUse?.org?.id}`}
          />
        )

      return null
    },
  },

  org: {
    Header  : 'Org',
    accessor: memoize(
      (originalRow: any) => originalRow?.mongoExtras?.currentUse?.org?.name,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      if (value)
        return (
          <InternalLink
            name={value}
            href={`/orgs/${row?.original?.mongoExtras?.currentUse?.org?.id}`}
          />
        )

      return null
    },
  },

  project: {
    Header  : 'Project',
    accessor: memoize(
      (originalRow: any) => originalRow?.mongoExtras?.currentUse?.project?.name,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      if (value)
        return (
          <InternalLink
            name={value}
            href={`/projects/${row?.original?.mongoExtras?.currentUse?.project?.id}`}
          />
        )

      return null
    },
  },

  adminProject: {
    Header  : 'Project',
    accessor: memoize(
      (originalRow: any) => originalRow?.mongoExtras?.currentUse?.project?.name,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      return (
        <InternalLink
          name={value}
          href={`/projects/${row?.original?.mongoExtras?.currentUse?.project?.id}`}
        />
      )
    },
  },

  modemStatus: {
    Header  : 'Modem Status',
    accessor: memoize((originalRow) => {
      return originalRow?.cloudloop?.subscriber?.contract?.networkStatus
        ? originalRow?.cloudloop?.subscriber?.contract?.networkStatus.toLowerCase()
        : originalRow?.cloudloop?.subscriber?.contract === null
          ? 'n/a'
          : null
    }),
  },

  lastLogged: {
    Header  : 'Last Transmission',
    accessor: 'mongoExtras.lastReading.ts.email',
    Cell    : ({ value }: LoggerCell) =>
      value !== undefined && value !== 0 && value !== null
        ? formatDate(new Date(value), 'yyyy-MM-dd')
        : null,
  },

  lastVoltage: {
    Header  : 'Last Voltage',
    accessor: 'mongoExtras.lastReading.logger.battery',
    Cell    : ({ value }: LoggerCell) => (
      <Text
        as='span'
        color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
      >
        {value ? `${value.toFixed(2)} V` : null}
      </Text>
    ),
  },

  lastTransmission: {
    Header    : 'Last Transmission',
    isCentered: true,
    columns   : [
      {
        Header  : 'Time',
        accessor: 'mongoExtras.lastReading.ts.email',
        Cell    : ({ value, row }: LoggerCell) => (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        ),
      },
      {
        Header  : 'Voltage',
        accessor: 'mongoExtras.lastReading.logger.battery',
        Cell    : ({ value }: LoggerCell) => (
          <Text
            as='span'
            color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
          >
            {value ? `${value.toFixed(2)} V` : null}
          </Text>
        ),
      },
    ],
  },

  adminLastTransmission: {
    Header    : 'Last Transmission',
    isCentered: true,
    columns   : [
      {
        id      : 'adminLastTransmissionTime table-col-gray',
        Header  : 'Time',
        accessor: 'mongoExtras.lastReading.ts.email',
        Cell    : ({ value, row }: LoggerCell) => (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        ),
      },
      {
        id      : 'adminLastTransmissionVoltage table-col-gray',
        Header  : 'Voltage',
        accessor: 'mongoExtras.lastReading.logger.battery',
        Cell    : ({ value }: LoggerCell) => (
          <Text
            as='span'
            color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
          >
            {value ? `${value.toFixed(2)} V` : null}
          </Text>
        ),
      },
      {
        id      : 'adminLastTransmissionBytes table-col-gray',
        Header  : 'Bytes',
        accessor: memoize((originalRow: any) =>
          originalRow?.mongoExtras?.lastReading?.msgSize
            ? `${originalRow?.mongoExtras?.lastReading?.msgSize} bytes`
            : null,
        ),
        Cell: ({ value, row }: LoggerCell) => {
          return (
            <Text
              as='span'
              color={
                row?.original?.mongoExtras?.lastReading?.msgSize <= 7
                  ? 'red'
                  : 'green'
              }
            >
              {value}
            </Text>
          )
        },
      },
      {
        id      : 'adminLastTransmissionErrors table-col-gray',
        Header  : 'Errors',
        accessor: 'extras.readingErrors',
        Cell    : ({ value }: LoggerCell) => {
          const res = []
          if (value) {
            if (value['85'] > 0)
              res.push(
                <Text key={'85'} color='red'>
                  85: {value['85']}
                </Text>,
              )
            if (value['99'] > 0)
              res.push(
                <Text key={'99'} color='red'>
                  99: {value['99']}
                </Text>,
              )
            if (value['125'] > 0)
              res.push(
                <Text key={'127'} color='red'>
                  125: {value['125']}
                </Text>,
              )
          }

          return <VStack>{res}</VStack>
        },
      },
    ],
  },

  actions: actions({
    formInfo : updateLoggerForm,
    deleteFn : deleteLogger,
    changelog: true,
  }),
}

const projections = {
  default: [
    columns.id,
    columns.serial,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.app,
    columns.imei,
    columns.project,
    columns.org,
  ],

  orgPage: [
    columns.active,
    columns.startDate,
    columns.endDate,
    columns.renewalDate,
    columns.serialNoLink,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.project,
  ],

  adminOrgPage: [
    columns.id,
    columns.active,
    columns.startDate,
    columns.endDate,
    columns.renewalDate,
    columns.serial,
    columns.adminLastTransmission,
    columns.batterySparkline,
    columns.app,
    columns.condition,
    columns.mounting_field,
    columns.imei,
    columns.modemStatus,
    columns.adminOrg,
    columns.adminProject,
    columns.notes,
    columns.actions,
  ],

  projectPage: [
    // columns.id,
    columns.serialNoLink,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
  ],

  adminProjectPage: [
    columns.id,
    columns.adminSerial,
    columns.adminLastTransmission,
    columns.batterySparkline,
    columns.adminApp,
    columns.adminCondition,
    columns.mounting_field,
    columns.imei,
    columns.modemStatus,
    columns.notes,
    columns.actions,
  ],

  globalPage: [
    columns.serialNoLink,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.project,
    columns.org,
  ],

  adminGlobalPage: [
    columns.id,
    columns.active,
    columns.startDate,
    columns.endDate,
    columns.renewalDate,
    columns.serial,
    columns.adminLastTransmission,
    columns.batterySparkline,
    columns.app,
    columns.condition,
    columns.mounting_field,
    columns.imei,
    columns.modemStatus,
    columns.adminOrg,
    columns.adminProject,
    columns.notes,
    columns.actions,
  ],
}

export type LoggersTableProjection = KeysOf<typeof projections>
