import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.maxio_subscription_note_ */
export type MaxioSubscriptionNoteId = string & {
  __brand: 'MaxioSubscriptionNoteId'
}

/** Represents the table public.maxio_subscription_note_ */
export default interface MaxioSubscriptionNoteTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    MaxioSubscriptionNoteId,
    MaxioSubscriptionNoteId | undefined,
    MaxioSubscriptionNoteId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  maxioSubscriptionId: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  maxioNoteId: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  note: ColumnType<string, string, string>

  /** Database type: pg_catalog.bool */
  sticky: ColumnType<boolean | null, boolean | null, boolean | null>
}

export type MaxioSubscriptionNote = Selectable<MaxioSubscriptionNoteTable>

export type MaxioSubscriptionNoteInitializer =
  Insertable<MaxioSubscriptionNoteTable>

export type MaxioSubscriptionNoteMutator =
  Updateable<MaxioSubscriptionNoteTable>

export const maxioSubscriptionNoteId =
  z.string() as unknown as z.Schema<MaxioSubscriptionNoteId>

export const maxioSubscriptionNote = z.object({
  id                 : maxioSubscriptionNoteId,
  createdAt          : z.coerce.date().nullable().optional(),
  createdBy          : z.string().nullable().optional(),
  updatedAt          : z.coerce.date().nullable().optional(),
  updatedBy          : z.string().nullable().optional(),
  deletedAt          : z.coerce.date().nullable().optional(),
  deletedBy          : z.string().nullable().optional(),
  maxioSubscriptionId: z.number(),
  maxioNoteId        : z.number(),
  note               : z.string(),
  sticky             : z.boolean().nullable().optional(),
}) as unknown as z.Schema<MaxioSubscriptionNote>

export const maxioSubscriptionNoteInitializer = z.object({
  id                 : maxioSubscriptionNoteId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : z.string().optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : z.string().optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : z.string().optional().nullable().optional(),
  maxioSubscriptionId: z.number(),
  maxioNoteId        : z.number(),
  note               : z.string(),
  sticky             : z.boolean().optional().nullable().optional(),
}) as unknown as z.Schema<MaxioSubscriptionNoteInitializer>

export const maxioSubscriptionNoteMutator = z.object({
  id                 : maxioSubscriptionNoteId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : z.string().optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : z.string().optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : z.string().optional().nullable().optional(),
  maxioSubscriptionId: z.number().optional(),
  maxioNoteId        : z.number().optional(),
  note               : z.string().optional(),
  sticky             : z.boolean().optional().nullable().optional(),
}) as unknown as z.Schema<MaxioSubscriptionNoteMutator>
