import { z } from 'zod'

/** Represents the enum public.service_provider_type_ */
enum ServiceProviderType {
  Maxio = 'Maxio',
}

export default ServiceProviderType

/** Zod schema for service_provider_type_ */
export const serviceProviderType = z.enum(['Maxio'])
