import {
  ApiKeyType,
  CableId,
  GlobalPerms,
  GlobalRoleType,
  KeysOf,
  LoggerId,
  OrganizationId,
  OrganizationPerms,
  OrganizationRoleType,
  ProjectId,
  ProjectPerms,
  ProjectRoleType,
  SiteId,
  UserId,
  UserWithPermissions,
} from '../../index.js'

export enum category {
  GLOBAL = 'GLOBAL',
  CABLE = 'CABLE',
  CABLE_PRICES = 'CABLE_PRICES',
  CLOUDLOOP = 'CLOUDLOOP',
  LOGGER = 'LOGGER',
  MODEM = 'MODEM',
  ORGANIZATION = 'ORGANIZATION',
  PERMISSION = 'PERMISSION',
  PROJECT = 'PROJECT',
  READINGS = 'READINGS',
  SENSOR = 'SENSOR',
  SEARCH = 'SEARCH',
  SITE = 'SITE',
  USER = 'USER',
  MAXIO = 'MAXIO',
}

export enum perms {
  CREATE_LOGGER = 'CREATE_LOGGER',
  VIEW_LOGGER = 'VIEW_LOGGER',
  LIST_LOGGERS = 'LIST_LOGGERS',
  EDIT_LOGGER = 'EDIT_LOGGER',
  REMOVE_LOGGER = 'REMOVE_LOGGER',
  SUSPEND_LOGGER = 'SUSPEND_LOGGER',
  VALIDATE_LOGGER = 'VALIDATE_LOGGER',
  SEND_SBD_TO_LOGGER = 'SEND_SBD_TO_LOGGER',
  CREATE_LOGGER_EVENT = 'CREATE_LOGGER_EVENT',
  CAPTURE_LOGGER_DIAGNOSTIC_EVENT = 'CAPTURE_LOGGER_DIAGNOSTIC_EVENT',
  CREATE_LOGGER_HISTORY_ENTRY = 'CREATE_LOGGER_HISTORY_ENTRY',
  LOGGER_FIRMWARE_LIST_FILES = 'LOGGER_FIRMWARE_LIST_FILES',
  LOGGER_FIRMWARE_PUBLISH = 'LOGGER_FIRMWARE_PUBLISH',

  LIST_MODEMS = 'LIST_MODEMS',
  VIEW_MODEM = 'VIEW_MODEM',
  CREATE_MODEM = 'CREATE_MODEM',
  EDIT_MODEM = 'EDIT_MODEM',
  REMOVE_MODEM = 'REMOVE_MODEM',
  VALIDATE_MODEM = 'VALIDATE_MODEM',
  VIEW_MODEM_HISTORY_ENTRY = 'VIEW_MODEM_HISTORY_ENTRY',
  CREATE_MODEM_HISTORY_ENTRY = 'CREATE_MODEM_HISTORY_ENTRY',

  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  VIEW_ORGANIZATION = 'VIEW_ORGANIZATION',
  VIEW_ORGANIZATION_NAME = 'VIEW_ORGANIZATION_NAME',
  LIST_ORGANIZATIONS = 'LIST_ORGANIZATIONS',
  EDIT_ORGANIZATION = 'EDIT_ORGANIZATION',
  REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION',

  CREATE_PROJECT = 'CREATE_PROJECT',
  VIEW_PROJECT = 'VIEW_PROJECT',
  LIST_PROJECTS = 'LIST_PROJECTS',
  EDIT_PROJECT = 'EDIT_PROJECT',
  REMOVE_PROJECT = 'REMOVE_PROJECT',

  CREATE_CABLE = 'CREATE_CABLE',
  VIEW_CABLE = 'VIEW_CABLE',
  LIST_CABLES = 'LIST_CABLES',
  EDIT_CABLE = 'EDIT_CABLE',
  REMOVE_CABLE = 'REMOVE_CABLE',
  EDIT_LOCKED_CABLE = 'EDIT_LOCKED_CABLE',

  CREATE_SITE = 'CREATE_SITE',
  VIEW_SITE = 'VIEW_SITE',
  LIST_SITES = 'LIST_SITES',
  LIST_ALL_SITES = 'LIST_ALL_SITES',
  EDIT_SITE = 'EDIT_SITE',
  REMOVE_SITE = 'REMOVE_SITE',
  BULK_CREATE_SITE = 'BULK_CREATE_SITE',

  CREATE_USER = 'CREATE_USER',

  LIST_PROJECT_USERS = 'LIST_PROJECT_USERS',

  LIST_ORGANIZATION_USERS = 'LIST_ORGANIZATION_USERS',

  LIST_GLOBAL_USERS = 'LIST_GLOBAL_USERS',

  VIEW_USER = 'VIEW_USER',
  LIST_USERS = 'LIST_USERS',
  EDIT_USER = 'EDIT_USER',
  REMOVE_USER = 'REMOVE_USER',
  ASSUME_USER_IDENTITY = 'ASSUME_USER_IDENTITY',

  MOVE_LOGGER_TO_PROJECT = 'MOVE_LOGGER_TO_PROJECT',
  MOVE_LOGGER_FROM_PROJECT = 'MOVE_LOGGER_FROM_PROJECT',

  MOVE_LOGGER_TO_ORGANIZATION = 'MOVE_LOGGER_TO_ORGANIZATION',
  MOVE_LOGGER_FROM_ORGANIZATION = 'MOVE_LOGGER_FROM_ORGANIZATION',

  MOVE_CABLE_TO_PROJECT = 'MOVE_CABLE_TO_PROJECT',
  MOVE_CABLE_FROM_PROJECT = 'MOVE_CABLE_FROM_PROJECT',

  MOVE_CABLE_TO_ORGANIZATION = 'MOVE_CABLE_TO_ORGANIZATION',
  MOVE_CABLE_FROM_ORGANIZATION = 'MOVE_CABLE_FROM_ORGANIZATION',

  MOVE_SITE_TO_PROJECT = 'MOVE_SITE_TO_PROJECT',
  MOVE_SITE_FROM_PROJECT = 'MOVE_SITE_FROM_PROJECT',

  MOVE_PROJECT_TO_ORGANIZATION = 'MOVE_PROJECT_TO_ORGANIZATION',
  MOVE_PROJECT_FROM_ORGANIZATION = 'MOVE_PROJECT_FROM_ORGANIZATION',

  ADD_SHARED_PROJECT_TO_ORGANIZATION = 'ADD_SHARED_PROJECT_TO_ORGANIZATION',

  READ_CABLE_PRICE = 'READ_CABLE_PRICE',
  LIST_CABLE_PRICES = 'LIST_CABLE_PRICES',
  CREATE_CABLE_PRICES = 'CREATE_CABLE_PRICES',

  CREATE_CABLE_EVENT = 'CREATE_CABLE_EVENT',
  CREATE_CABLE_HISTORY_ENTRY = 'CREATE_CABLE_HISTORY_ENTRY',

  DOWNLOAD_FILE_CABLE_CUTTER = 'DOWNLOAD_FILE_CABLE_CUTTER',
  DOWNLOAD_FILE_FISHBOWL = 'DOWNLOAD_FILE_FISHBOWL',

  CLOUDLOOP_ACTIVATE_SUBSCRIBER = 'CLOUDLOOP_ACTIVATE_SUBSCRIBER',
  CLOUDLOOP_CREATE_DESTINATION = 'CLOUDLOOP_CREATE_DESTINATION',
  CLOUDLOOP_CREATE_HARDWARE = 'CLOUDLOOP_CREATE_HARDWARE',
  CLOUDLOOP_CREATE_SUBSCRIBER = 'CLOUDLOOP_CREATE_SUBSCRIBER',
  CLOUDLOOP_DEACTIVATE_SUBSCRIBER = 'CLOUDLOOP_DEACTIVATE_SUBSCRIBER',
  CLOUDLOOP_GET_HARDWARE = 'CLOUDLOOP_GET_HARDWARE',
  CLOUDLOOP_GET_SUBSCRIBER = 'CLOUDLOOP_GET_SUBSCRIBER',
  CLOUDLOOP_LIST_SUBSCRIBERS = 'CLOUDLOOP_LIST_SUBSCRIBERS',
  CLOUDLOOP_GET_USAGE_SUMMARY = 'CLOUDLOOP_GET_USAGE_SUMMARY',
  CLOUDLOOP_REASSOCIATE_SUBSCRIBER = 'CLOUDLOOP_REASSOCIATE_SUBSCRIBER',
  CLOUDLOOP_RESUME_SUBSCRIBER = 'CLOUDLOOP_RESUME_SUBSCRIBER',
  CLOUDLOOP_SEND_MESSAGE = 'CLOUDLOOP_SEND_MESSAGE',
  CLOUDLOOP_SUSPEND_SUBSCRIBER = 'CLOUDLOOP_SUSPEND_SUBSCRIBER',
  CLOUDLOOP_UPDATE_SUBSCRIBER = 'CLOUDLOOP_UPDATE_SUBSCRIBER',
  CLOUDLOOP_SETUP_WIZARD = 'CLOUDLOOP_SETUP_WIZARD',

  CREATE_SENSOR = 'CREATE_SENSOR',
  VIEW_SENSOR = 'VIEW_SENSOR',
  LIST_SENSORS = 'LIST_SENSORS',
  EDIT_SENSOR = 'EDIT_SENSOR',
  REMOVE_SENSOR = 'REMOVE_SENSOR',
  CALIBRATE_SENSOR = 'CALIBRATE_SENSOR',
  REMOVE_SENSOR_CALIBRATION = 'REMOVE_SENSOR_CALIBRATION',
  VIEW_SENSOR_CALIBRATIONS_FOR_SITE = 'VIEW_SENSOR_CALIBRATIONS_FOR_SITE',
  VIEW_SENSOR_CALIBRATIONS_FOR_CABLE = 'VIEW_SENSOR_CALIBRATIONS_FOR_CABLE',

  SEARCH = 'SEARCH',

  CREATE_READING = 'CREATE_READING',
  LIST_READINGS = 'LIST_READINGS',
  VIEW_READING = 'VIEW_READING',
  REPROCESS_READINGS = 'REPROCESS_READINGS',
  EDIT_READING = 'EDIT_READING',

  CAN_ASSIGN_GLOBAL_ADMIN = 'CAN_ASSIGN_GLOBAL_ADMIN',
  CAN_ASSIGN_GLOBAL_EDIT = 'CAN_ASSIGN_GLOBAL_EDIT',
  CAN_ASSIGN_GLOBAL_VIEW = 'CAN_ASSIGN_GLOBAL_VIEW',

  CAN_ASSIGN_LOGGER_ADMIN = 'CAN_ASSIGN_LOGGER_ADMIN',
  CAN_ASSIGN_CABLE_ADMIN = 'CAN_ASSIGN_CABLE_ADMIN',
  CAN_ASSIGN_SALES_ADMIN = 'CAN_ASSIGN_SALES_ADMIN',

  CAN_ASSIGN_ORGANIZATION_ADMIN = 'CAN_ASSIGN_ORGANIZATION_ADMIN',
  CAN_ASSIGN_ORGANIZATION_EDIT = 'CAN_ASSIGN_ORGANIZATION_EDIT',
  CAN_ASSIGN_ORGANIZATION_VIEW = 'CAN_ASSIGN_ORGANIZATION_VIEW',

  CAN_ASSIGN_PROJECT_ADMIN = 'CAN_ASSIGN_PROJECT_ADMIN',
  CAN_ASSIGN_PROJECT_EDIT = 'CAN_ASSIGN_PROJECT_EDIT',
  CAN_ASSIGN_PROJECT_VIEW = 'CAN_ASSIGN_PROJECT_VIEW',

  LIST_USER_PERMISSIONS = 'LIST_USER_PERMISSIONS',

  MAXIO_CREATE_CUSTOMER_SUBSCRIPTION = 'MAXIO_CREATE_CUSTOMER_SUBSCRIPTION',
  MAXIO_CREATE_SUBSCRIPTION = 'MAXIO_CREATE_SUBSCRIPTION',
  MAXIO_UPDATE_SUBSCRIPTION = 'MAXIO_UPDATE_SUBSCRIPTION',
  MAXIO_LIST_SUBSCRIPTIONS = 'MAXIO_LIST_SUBSCRIPTIONS',
  MAXIO_CREATE_CUSTOMER = 'MAXIO_CREATE_CUSTOMER',
  MAXIO_GET_CUSTOMER_PAYMENT_PROFILE = 'MAXIO_GET_CUSTOMER_PAYMENT_PROFILE',
  MAXIO_GET_PRODUCTS = 'MAXIO_GET_PRODUCTS',
  MAXIO_LIST_COUPONS = 'MAXIO_LIST_COUPONS',
  MAXIO_CREATE_SUBSCRIPTION_ADMIN_PREPAYMENT = 'MAXIO_CREATE_SUBSCRIPTION_ADMIN_PREPAYMENT',

  LOCK_CABLE = 'LOCK_CABLE',
}

const globalAdminExcludedPermissions = [
  perms.CAPTURE_LOGGER_DIAGNOSTIC_EVENT,
  perms.CREATE_READING,
  perms.EDIT_READING,
  // perms.MAXIO_CREATE_CUSTOMER_SUBSCRIPTION,
]

const globalEditExcludedPermissions = [
  ...globalAdminExcludedPermissions,
  perms.REMOVE_LOGGER,
  perms.REMOVE_MODEM,
  perms.EDIT_LOCKED_CABLE,
  perms.ADD_SHARED_PROJECT_TO_ORGANIZATION,
  perms.LOGGER_FIRMWARE_LIST_FILES,
  perms.LOGGER_FIRMWARE_PUBLISH,
  perms.CREATE_CABLE_PRICES,
  perms.CAN_ASSIGN_GLOBAL_ADMIN,
  perms.CAN_ASSIGN_LOGGER_ADMIN,
  perms.CAN_ASSIGN_CABLE_ADMIN,
  perms.CAN_ASSIGN_SALES_ADMIN,
  perms.LOCK_CABLE,
]

type RoleType = ProjectRoleType | OrganizationRoleType | GlobalRoleType

export const CAPTURE_APP_PERMISSIONS: Array<KeysOf<typeof perms>> = [
  perms.CAPTURE_LOGGER_DIAGNOSTIC_EVENT,
  perms.CLOUDLOOP_GET_SUBSCRIBER,
]

export const SDM_APP_PERMISSIONS: Array<KeysOf<typeof perms>> = [
  perms.LIST_CABLES,
  perms.LIST_SITES,
  perms.LIST_ALL_SITES,
  perms.LIST_READINGS,
  perms.CREATE_READING,
]

export const ETHERNET_ACTIVATOR_PERMISSIONS: Array<KeysOf<typeof perms>> = [
  perms.CREATE_READING,
]

const GLOBAL_ADMIN = [
  ...Object.values(perms).filter(
    (p) => !globalAdminExcludedPermissions.includes(p),
  ),
]

const GLOBAL_EDIT = [
  ...Object.values(perms).filter(
    (p) => !globalEditExcludedPermissions.includes(p),
  ),
]

const GLOBAL_VIEW = [
  perms.VIEW_ORGANIZATION,
  perms.LIST_ORGANIZATIONS,
  perms.VIEW_PROJECT,
  perms.LIST_PROJECTS,
  perms.VIEW_SITE,
  perms.LIST_SITES,
  perms.VIEW_CABLE,
  perms.LIST_CABLES,
  perms.VIEW_LOGGER,
  perms.LIST_LOGGERS,
  perms.LIST_ORGANIZATION_USERS,
  perms.LIST_PROJECT_USERS,
  perms.LIST_MODEMS,
  perms.VIEW_MODEM,
  perms.VIEW_SENSOR_CALIBRATIONS_FOR_SITE,
  perms.SEARCH,
  perms.VIEW_MODEM_HISTORY_ENTRY,
  perms.VIEW_USER,
  perms.CLOUDLOOP_GET_HARDWARE,
  perms.CLOUDLOOP_GET_SUBSCRIBER,
  perms.CLOUDLOOP_LIST_SUBSCRIBERS,
  perms.CLOUDLOOP_GET_USAGE_SUMMARY,
  perms.LIST_USERS,
  perms.EDIT_USER,
  perms.READ_CABLE_PRICE,
  perms.VIEW_READING,
  perms.LIST_READINGS,
  perms.LIST_GLOBAL_USERS,
  perms.LIST_USER_PERMISSIONS,
  perms.VIEW_ORGANIZATION_NAME,
  perms.MAXIO_LIST_SUBSCRIPTIONS,
]

const CABLE_ADMIN = [
  perms.EDIT_LOCKED_CABLE,
  perms.CREATE_SENSOR,
  perms.VIEW_SENSOR,
  perms.LIST_SENSORS,
  perms.EDIT_SENSOR,
  perms.CALIBRATE_SENSOR,
  perms.REMOVE_SENSOR,
  perms.REMOVE_SENSOR_CALIBRATION,
  perms.CAN_ASSIGN_CABLE_ADMIN,
  perms.LOCK_CABLE,
]

const LOGGER_ADMIN = [
  perms.LOGGER_FIRMWARE_LIST_FILES,
  perms.LOGGER_FIRMWARE_PUBLISH,
  perms.REMOVE_LOGGER,
  perms.REMOVE_MODEM,
  perms.CAN_ASSIGN_LOGGER_ADMIN,
]

const SALES_ADMIN = [
  perms.LIST_CABLE_PRICES,
  perms.CREATE_CABLE_PRICES,
  perms.CAN_ASSIGN_SALES_ADMIN,
]

const PROJECT_VIEW = [
  perms.VIEW_ORGANIZATION_NAME,
  perms.VIEW_PROJECT,
  perms.LIST_PROJECTS,
  perms.VIEW_SITE,
  perms.LIST_SITES,
  perms.VIEW_CABLE,
  perms.LIST_CABLES,
  perms.LIST_LOGGERS,
  perms.VIEW_SENSOR_CALIBRATIONS_FOR_SITE,
  perms.VIEW_USER,
  perms.EDIT_USER,
  perms.READ_CABLE_PRICE,
  perms.LIST_READINGS,
]

const PROJECT_EDIT = [
  ...PROJECT_VIEW,
  perms.EDIT_PROJECT,
  perms.CREATE_SITE,
  perms.EDIT_SITE,
  perms.REMOVE_SITE,
  perms.LIST_PROJECT_USERS,
  perms.CAN_ASSIGN_PROJECT_VIEW,
]

const PROJECT_ADMIN = [
  ...PROJECT_EDIT,
  perms.REMOVE_SITE,
  perms.CAN_ASSIGN_PROJECT_ADMIN,
  perms.CAN_ASSIGN_PROJECT_EDIT,
]

const ORGANIZATION_VIEW = [...PROJECT_VIEW, perms.VIEW_ORGANIZATION]

const ORGANIZATION_EDIT = [
  ...ORGANIZATION_VIEW,
  ...PROJECT_EDIT,
  perms.EDIT_ORGANIZATION,
  perms.CREATE_PROJECT,
  // perms.CAN_ASSIGN_ORGANIZATION_VIEW,
  // perms.CAN_ASSIGN_ORGANIZATION_EDIT,
  perms.CAN_ASSIGN_PROJECT_EDIT,
]

const ORGANIZATION_ADMIN = [
  ...ORGANIZATION_EDIT,
  ...PROJECT_ADMIN,
  perms.CREATE_PROJECT,
  perms.REMOVE_PROJECT,
  perms.LIST_ORGANIZATION_USERS,
  perms.CAN_ASSIGN_ORGANIZATION_VIEW,
  perms.CAN_ASSIGN_ORGANIZATION_EDIT,
  perms.CAN_ASSIGN_ORGANIZATION_ADMIN,
  perms.VIEW_ORGANIZATION_NAME,
  perms.MAXIO_CREATE_SUBSCRIPTION,
  perms.MAXIO_UPDATE_SUBSCRIPTION,
  perms.MAXIO_GET_PRODUCTS,
  perms.MAXIO_GET_CUSTOMER_PAYMENT_PROFILE,
  perms.MAXIO_LIST_SUBSCRIPTIONS,
]

export const rolePermissions: {
  [key in RoleType]: Array<KeysOf<typeof perms>>
} = {
  GLOBAL_ADMIN,
  GLOBAL_EDIT,
  GLOBAL_VIEW,
  CABLE_ADMIN,
  LOGGER_ADMIN,
  SALES_ADMIN,
  PROJECT_VIEW,
  PROJECT_EDIT,
  PROJECT_ADMIN,
  ORGANIZATION_VIEW,
  ORGANIZATION_EDIT,
  ORGANIZATION_ADMIN,
}

export type HasPermissionContext = {
  username?: string
  id?: number
  organizationId?: OrganizationId
  projectId?: ProjectId
  siteId?: SiteId
  cableId?: CableId
  loggerId?: LoggerId
  userId?: UserId
  serial?: string | number | { 'serial.model': string; 'serial.id': number }
  maxioCustomerId?: number
}

export const roleHasPermission =
  (action: keyof typeof perms) =>
    (p: GlobalPerms | OrganizationPerms | ProjectPerms) =>
      rolePermissions[p.role]?.includes(action)

const userHasOrganizationRole = (context: HasPermissionContext) => (o: any) =>
  o.role !== null && o.id === context.organizationId

const userHasAnyOrganizationRole =
  (context: HasPermissionContext) => (o: any) =>
    o.role !== null

const userHasProjectRole = (context: HasPermissionContext) => (p: any) =>
  p.role !== null &&
  (context.projectId !== undefined
    ? context.organizationId !== undefined
      ? p.id === context.projectId &&
        p.organizationId === context.organizationId
      : p.id === context.projectId
    : p.organizationId === context.organizationId)

const userHasAnyProjectRole = (context: HasPermissionContext) => (p: any) =>
  p.role !== null

const hasMaxioConfig = (context: HasPermissionContext) =>
  context.maxioCustomerId !== undefined

const wantsMaxio = (action: keyof typeof perms) => action.startsWith('MAXIO')

export const hasPermission = (
  user: UserWithPermissions,
  action: keyof typeof perms,
  context: HasPermissionContext,
) => {
  // console.log(user, action, context)
  // console.log(context)
  if (user.permissions?.global?.some(roleHasPermission(action))) {
    return true
  }

  // console.log('no global perm', user, action, context)

  if (
    context?.organizationId !== undefined &&
    user.permissions?.organizations
      ?.filter(userHasOrganizationRole(context))
      ?.some(roleHasPermission(action))
  ) {
    if (wantsMaxio(action) && !hasMaxioConfig(context)) return false

    return true
  }

  if (
    context?.organizationId !== undefined &&
    user.permissions?.projects
      ?.filter(userHasProjectRole(context))
      ?.some(roleHasPermission(action))
  )
    return true

  if (
    context?.organizationId === undefined &&
    context?.projectId === undefined &&
    user.permissions?.organizations
      ?.filter(userHasAnyOrganizationRole(context))
      ?.some(roleHasPermission(action))
  )
    return true

  if (
    context.projectId !== undefined &&
    user.permissions?.projects
      ?.filter(userHasProjectRole(context))
      ?.some(roleHasPermission(action))
  )
    return true

  if (
    context?.organizationId === undefined &&
    context.projectId === undefined &&
    user.permissions?.projects
      ?.filter(userHasAnyProjectRole(context))
      ?.some(roleHasPermission(action))
  )
    return true

  if (context.username !== undefined && isGUID(context.username)) {
    const apiKeyType = user.apiKeys?.find(
      (k) => k.key === context.username,
    )?.type

    if (
      apiKeyType === ApiKeyType.CAPTUREAPP &&
      CAPTURE_APP_PERMISSIONS.includes(action)
    )
      return true

    if (apiKeyType === ApiKeyType.SDM && SDM_APP_PERMISSIONS.includes(action)) {
      return true
    }

    if (
      apiKeyType === ApiKeyType.ETHERNET_ACTIVATOR &&
      ETHERNET_ACTIVATOR_PERMISSIONS.includes(action)
    ) {
      return true
    }
  }

  if (
    (action === perms.VIEW_USER || action === perms.EDIT_USER) &&
    context.username === user.email &&
    (context.userId === 'me' || context.userId === user.id)
  ) {
    // console.log('user is viewing or editing themselves')
    return true
  }

  console.info(`no perm\n${user.id} : ${user.email}`, action, context)

  return false
}

export const isGUID = (str: string) => {
  const regex = new RegExp(
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
  )
  return regex.test(str)
}
