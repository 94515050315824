import { z } from 'zod'

import {
  organizationId,
  maxioLoggerSubscriptionId,
  ProjectId,
  CouponCodeType,
  couponCodeType,
  projectId,
} from '@beaded/models'
import {
  MaxioLoggerSubscriptionId,
  OrganizationId,
  UserId,
} from '@beaded/models'

export type CreateLoggerSubscriptionAssingment = {
  orgId: number
  maxioProjectSubscriptionId: number
  loggerSerial: string
  notes: string
}

export const CreateLoggerSubscriptionAssingmentSchema = z.object({
  orgId                     : z.number().int(),
  maxioProjectSubscriptionId: z.number().int(),
  loggerSerial              : z.string(),
  notes                     : z.string().optional(),
})

export type CreateMultipleSubscriptions = {
  subscriptionBody: {
    productHandle?: string
    reference?: string
    initialBillingAt?: string
    metafields?: {
      productType?: string
      projectName?: string
      monthsRemaining?: string
      monthsPurchased?: string
      numberOfDevices?: string
      isNew?: 'true' | 'false'
    }
  }

  maxioCustomerId: number

  paymentProfileId?: number
  payerReference?: string
  paymentCollectionMethod?: string
  paymentSelector: 'new' | 'existing'
  paymentType?: 'card' | 'bank'
  chargifyToken?: string

  userId: UserId
  organizationId: OrganizationId
  projectId: ProjectId
  notes?: string

  subscriptionDetails: {
    subscriptionInputSelection: 'loggers' | 'newSubscriptions'
    loggerSerials?: string[]
    numberOfSubscriptions: number
    numberOfTimeUnits: number
    timeUnits: 'months' | 'years'
  }

  adminPrepayment?: {
    amount: number
    method: 'cash' | 'check' | 'other'
    details: string
    memo: string
    couponCode?: string
  }

  unitPrice?: number
  totalPrice?: number
  couponCode?: CouponCodeType

  id?: MaxioLoggerSubscriptionId
}

export const subscriptionSchema = z.object({
  // subscriptionBody will be used to populate the subscription group
  subscriptionBody: z.object({
    productHandle   : z.string().optional(),
    reference       : z.string().optional(),
    initialBillingAt: z.string().optional(),
    metafields      : z
      .object({
        productType    : z.string().optional(),
        projectName    : z.string().optional(),
        monthsRemaining: z.string().optional(),
        monthsPurchased: z.string().optional(),
        loggerSerial   : z.string().optional(), // populate this based on subscriptionDetails
        isNew          : z.enum(['true', 'false']).optional(),
      })
      .optional(),
  }),

  maxioCustomerId: z.number().int(),

  paymentProfileId       : z.number().positive().or(z.literal('')).optional(),
  payerReference         : z.string().optional(),
  paymentCollectionMethod: z.string().optional(),

  paymentSelector: z.enum(['new', 'existing']),
  paymentType    : z.enum(['card', 'bank', '']).optional(),
  chargifyToken  : z.string().optional(),

  userId        : z.string(),
  organizationId: organizationId,
  projectId     : projectId,
  notes         : z.string().optional(),

  subscriptionDetails: z.object({
    subscriptionInputSelection: z.enum(['loggers', 'newSubscriptions']),
    loggerSerials             : z.array(z.string()).optional(),
    numberOfSubscriptions     : z.number().int().optional(),
    numberOfTimeUnits         : z.number().int().optional(),
    timeUnits                 : z.enum(['months', 'years']).optional(),
  }),

  unitPrice : z.number().int().optional(),
  totalPrice: z.number().int().optional(),
  couponCode: couponCodeType.optional(),

  adminPrepayment: z
    .object({
      amount    : z.number().int(),
      method    : z.enum(['cash', 'check', 'other']),
      details   : z.string(),
      memo      : z.string(),
      couponCode: z.string().optional(),
    })
    .optional(),

  id: maxioLoggerSubscriptionId.optional(),
}) as unknown as z.Schema<CreateMultipleSubscriptions>

export type UpdateLoggerSubscription = {
  projectId?: ProjectId
  organizationId?: OrganizationId
  subscriptionId: number

  subscription?: {
    nextBillingAt?: string
    reference?: string
  }
  notes?: {
    noteId?: number
    updateNote: {
      body: string
      sticky: boolean
    }
  }[]
  metadata?: {
    loggerSerial?: string
  }
}

export const updateLoggerSubscriptionSchema = z.object({
  projectId     : projectId.optional(),
  organizationId: organizationId.optional(),
  subscriptionId: z.number().int(),

  subscription: z
    .object({
      nextBillingAt: z.string().optional(),
      reference    : z.string().optional(),
    })
    .optional(),

  notes: z
    .array(
      z.object({
        noteId    : z.number().int().optional(),
        updateNote: z
          .object({
            body  : z.string(),
            sticky: z.boolean(),
          })
          .optional(),
      }),
    )
    .optional(),

  metadata: z
    .object({
      loggerSerial: z.string().optional(),
    })
    .optional(),
}) as unknown as z.Schema<UpdateLoggerSubscription>

export const calculateSubscriptionPrice = ({
  productPrice,
  timeUnits,
  numberOfTimeUnits,
  numberOfSubscriptions,
  adminCouponCode,
}: {
  productPrice: number
  timeUnits: 'months' | 'years'
  numberOfTimeUnits: number
  numberOfSubscriptions: number
  adminCouponCode?: string
}) => {
  // customers get discounts for 1yr, 2yr, 3yr, 4yr, 5yr
  // discount is 7.50/mo
  //
  // discounts are applied by coupon codes:
  // YEARLY-DISCOUNT-1YR-PERIOD
  // YEARLY-DISCOUNT-2YR-PERIOD
  // YEARLY-DISCOUNT-3YR-PERIOD
  // YEARLY-DISCOUNT-4YR-PERIOD
  // YEARLY-DISCOUNT-5YR-PERIOD
  //
  // sponsored subscriptions are free via coupon code:
  // SPONSORED-SUBSCRIPTION

  let years = 0,
    months = 0,
    couponCode,
    totalMonths = 0

  if (timeUnits === 'months') {
    years = Math.floor(numberOfTimeUnits / 12)
    months = numberOfTimeUnits % 12
    totalMonths = numberOfTimeUnits
  }
  else {
    years = numberOfTimeUnits
    totalMonths = numberOfTimeUnits * 12
  }

  if (years > 0 && years <= 5) {
    couponCode = `YEARLY-DISCOUNT-${years}YR-PERIOD` as CouponCodeType
  }

  let unitPrice = years * 12 * (productPrice - 7.5) + months * productPrice

  if (adminCouponCode === CouponCodeType['SPONSORED-SUBSCRIPTION']) {
    couponCode = CouponCodeType['SPONSORED-SUBSCRIPTION']
    unitPrice = 0
  }

  const totalPrice = unitPrice * numberOfSubscriptions

  return {
    years,
    months,
    totalMonths,
    numberOfSubscriptions,
    unitPrice,
    totalPrice,
    couponCode,
  }
}
