import { Organization } from '@beaded/models'

import type { FormInfo } from 'types/FormInfo'

export const orgFields: FormInfo<Partial<Organization>>['fields'] = [
  {
    name       : 'organizationName',
    type       : 'text',
    label      : 'Organization Name',
    placeholder: 'Organization Name',
    isRequired : true,
  },
  {
    name : 'isFreeAccount',
    label: 'Free Account',
    type : 'checkbox',
  },
  {
    name : 'notes',
    label: 'Notes',
    type : 'textarea',
  },
  {
    name : 'copperId',
    label: 'Copper ID',
    type : 'number',
  },
  {
    name : 'copperUrl',
    label: 'Copper URL',
    type : 'text',
  },
]
