import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  couponCodeType,
  type default as CouponCodeType,
} from './CouponCodeType.js'
import { organizationId, type OrganizationId } from './Organization.js'
import { projectId, type ProjectId } from './Project.js'
import {
  serviceProviderType,
  type default as ServiceProviderType,
} from './ServiceProviderType.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.maxio_logger_subscription_ */
export type MaxioLoggerSubscriptionId = string & {
  __brand: 'MaxioLoggerSubscriptionId'
}

/** Represents the table public.maxio_logger_subscription_ */
export default interface MaxioLoggerSubscriptionTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    MaxioLoggerSubscriptionId,
    MaxioLoggerSubscriptionId | undefined,
    MaxioLoggerSubscriptionId
  >

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<UserId | null, UserId | null, UserId | null>

  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, OrganizationId, OrganizationId>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>

  /** Database type: pg_catalog.text */
  loggerSerial: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  maxioSubscriptionId: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  maxioSubscriptionGroupId: ColumnType<
    string | null,
    string | null,
    string | null
  >

  /** Database type: pg_catalog.text */
  maxioCouponCode: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  maxioReference: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  maxioCustomerId: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  maxioProductId: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  monthsPurchased: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  bytesUsed: ColumnType<number, number | undefined, number>

  /** Database type: public.service_provider_type_ */
  paymentServiceProvider: ColumnType<
    ServiceProviderType | null,
    ServiceProviderType | null,
    ServiceProviderType | null
  >

  /** Database type: public.coupon_code_type_ */
  couponCode: ColumnType<
    CouponCodeType | null,
    CouponCodeType | null,
    CouponCodeType | null
  >

  /** Database type: pg_catalog.timestamp */
  subscriptionCreated: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamp */
  subscriptionEstimatedStartDate: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamp */
  subscriptionBillingPeriodStartDate: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.timestamp */
  subscriptionBillingPeriodEndDate: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >

  /** Database type: pg_catalog.numeric */
  prepaidCents: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.numeric */
  creditCents: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  subscriptionState: ColumnType<string, string, string>
}

export type MaxioLoggerSubscription = Selectable<MaxioLoggerSubscriptionTable>

export type MaxioLoggerSubscriptionInitializer =
  Insertable<MaxioLoggerSubscriptionTable>

export type MaxioLoggerSubscriptionMutator =
  Updateable<MaxioLoggerSubscriptionTable>

export const maxioLoggerSubscriptionId =
  z.string() as unknown as z.Schema<MaxioLoggerSubscriptionId>

export const maxioLoggerSubscription = z.object({
  id                                : maxioLoggerSubscriptionId,
  createdAt                         : z.coerce.date().nullable().optional(),
  createdBy                         : userId.nullable().optional(),
  updatedAt                         : z.coerce.date().nullable().optional(),
  updatedBy                         : userId.nullable().optional(),
  deletedAt                         : z.coerce.date().nullable().optional(),
  deletedBy                         : userId.nullable().optional(),
  organizationId                    : organizationId,
  projectId                         : projectId,
  loggerSerial                      : z.string(),
  maxioSubscriptionId               : z.number(),
  maxioSubscriptionGroupId          : z.string().nullable().optional(),
  maxioCouponCode                   : z.string().nullable().optional(),
  maxioReference                    : z.string(),
  maxioCustomerId                   : z.number(),
  maxioProductId                    : z.number(),
  monthsPurchased                   : z.number(),
  bytesUsed                         : z.number(),
  paymentServiceProvider            : serviceProviderType.nullable().optional(),
  couponCode                        : couponCodeType.nullable().optional(),
  subscriptionCreated               : z.coerce.date(),
  subscriptionEstimatedStartDate    : z.coerce.date(),
  subscriptionBillingPeriodStartDate: z.coerce.date().nullable().optional(),
  subscriptionBillingPeriodEndDate  : z.coerce.date().nullable().optional(),
  prepaidCents                      : z.number().nullable().optional(),
  creditCents                       : z.number().nullable().optional(),
  subscriptionState                 : z.string(),
}) as unknown as z.Schema<MaxioLoggerSubscription>

export const maxioLoggerSubscriptionInitializer = z.object({
  id                                : maxioLoggerSubscriptionId.optional(),
  createdAt                         : z.coerce.date().optional().nullable().optional(),
  createdBy                         : userId.optional().nullable().optional(),
  updatedAt                         : z.coerce.date().optional().nullable().optional(),
  updatedBy                         : userId.optional().nullable().optional(),
  deletedAt                         : z.coerce.date().optional().nullable().optional(),
  deletedBy                         : userId.optional().nullable().optional(),
  organizationId                    : organizationId,
  projectId                         : projectId,
  loggerSerial                      : z.string(),
  maxioSubscriptionId               : z.number(),
  maxioSubscriptionGroupId          : z.string().optional().nullable().optional(),
  maxioCouponCode                   : z.string().optional().nullable().optional(),
  maxioReference                    : z.string(),
  maxioCustomerId                   : z.number(),
  maxioProductId                    : z.number(),
  monthsPurchased                   : z.number(),
  bytesUsed                         : z.number().optional(),
  paymentServiceProvider            : serviceProviderType.optional().nullable().optional(),
  couponCode                        : couponCodeType.optional().nullable().optional(),
  subscriptionCreated               : z.coerce.date(),
  subscriptionEstimatedStartDate    : z.coerce.date(),
  subscriptionBillingPeriodStartDate: z.coerce
    .date()
    .optional()
    .nullable()
    .optional(),
  subscriptionBillingPeriodEndDate: z.coerce
    .date()
    .optional()
    .nullable()
    .optional(),
  prepaidCents     : z.number().optional().nullable().optional(),
  creditCents      : z.number().optional().nullable().optional(),
  subscriptionState: z.string(),
}) as unknown as z.Schema<MaxioLoggerSubscriptionInitializer>

export const maxioLoggerSubscriptionMutator = z.object({
  id                                : maxioLoggerSubscriptionId.optional(),
  createdAt                         : z.coerce.date().optional().nullable().optional(),
  createdBy                         : userId.optional().nullable().optional(),
  updatedAt                         : z.coerce.date().optional().nullable().optional(),
  updatedBy                         : userId.optional().nullable().optional(),
  deletedAt                         : z.coerce.date().optional().nullable().optional(),
  deletedBy                         : userId.optional().nullable().optional(),
  organizationId                    : organizationId.optional(),
  projectId                         : projectId.optional(),
  loggerSerial                      : z.string().optional(),
  maxioSubscriptionId               : z.number().optional(),
  maxioSubscriptionGroupId          : z.string().optional().nullable().optional(),
  maxioCouponCode                   : z.string().optional().nullable().optional(),
  maxioReference                    : z.string().optional(),
  maxioCustomerId                   : z.number().optional(),
  maxioProductId                    : z.number().optional(),
  monthsPurchased                   : z.number().optional(),
  bytesUsed                         : z.number().optional(),
  paymentServiceProvider            : serviceProviderType.optional().nullable().optional(),
  couponCode                        : couponCodeType.optional().nullable().optional(),
  subscriptionCreated               : z.coerce.date().optional(),
  subscriptionEstimatedStartDate    : z.coerce.date().optional(),
  subscriptionBillingPeriodStartDate: z.coerce
    .date()
    .optional()
    .nullable()
    .optional(),
  subscriptionBillingPeriodEndDate: z.coerce
    .date()
    .optional()
    .nullable()
    .optional(),
  prepaidCents     : z.number().optional().nullable().optional(),
  creditCents      : z.number().optional().nullable().optional(),
  subscriptionState: z.string().optional(),
}) as unknown as z.Schema<MaxioLoggerSubscriptionMutator>
