import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { cableId, type CableId } from './Cable.js'
import {
  cableStatusType,
  type default as CableStatusType,
} from './CableStatusType.js'
import { organizationId, type OrganizationId } from './Organization.js'
import { projectId, type ProjectId } from './Project.js'

/** Identifier type for public.cable_history_ */
export type CableHistoryId = number & { __brand: 'CableHistoryId' }

/** Represents the table public.cable_history_ */
export default interface CableHistoryTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<CableHistoryId, CableHistoryId | undefined, CableHistoryId>

  /** Database type: pg_catalog.int4 */
  cableId: ColumnType<CableId, CableId, CableId>

  /** Database type: pg_catalog.timestamp */
  dateBegin: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamp */
  dateEnd: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: public.cable_status_type_ */
  condition: ColumnType<
    CableStatusType | null,
    CableStatusType | null,
    CableStatusType | null
  >

  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, OrganizationId, OrganizationId>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>
}

export type CableHistory = Selectable<CableHistoryTable>

export type CableHistoryInitializer = Insertable<CableHistoryTable>

export type CableHistoryMutator = Updateable<CableHistoryTable>

export const cableHistoryId = z.number() as unknown as z.Schema<CableHistoryId>

export const cableHistory = z.object({
  id            : cableHistoryId,
  cableId       : cableId,
  dateBegin     : z.coerce.date(),
  dateEnd       : z.coerce.date().nullable().optional(),
  condition     : cableStatusType.nullable().optional(),
  organizationId: organizationId,
  projectId     : projectId,
}) as unknown as z.Schema<CableHistory>

export const cableHistoryInitializer = z.object({
  id            : cableHistoryId.optional(),
  cableId       : cableId,
  dateBegin     : z.coerce.date(),
  dateEnd       : z.coerce.date().optional().nullable().optional(),
  condition     : cableStatusType.optional().nullable().optional(),
  organizationId: organizationId,
  projectId     : projectId,
}) as unknown as z.Schema<CableHistoryInitializer>

export const cableHistoryMutator = z.object({
  id            : cableHistoryId.optional(),
  cableId       : cableId.optional(),
  dateBegin     : z.coerce.date().optional(),
  dateEnd       : z.coerce.date().optional().nullable().optional(),
  condition     : cableStatusType.optional().nullable().optional(),
  organizationId: organizationId.optional(),
  projectId     : projectId.optional(),
}) as unknown as z.Schema<CableHistoryMutator>
