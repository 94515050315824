import { z } from 'zod'

/** Represents the enum public.coupon_code_type_ */
enum CouponCodeType {
  'SPONSORED-SUBSCRIPTION' = 'SPONSORED-SUBSCRIPTION',
  'YEARLY-DISCOUNT-1YR-PERIOD' = 'YEARLY-DISCOUNT-1YR-PERIOD',
  'YEARLY-DISCOUNT-2YR-PERIOD' = 'YEARLY-DISCOUNT-2YR-PERIOD',
  'YEARLY-DISCOUNT-3YR-PERIOD' = 'YEARLY-DISCOUNT-3YR-PERIOD',
  'YEARLY-DISCOUNT-4YR-PERIOD' = 'YEARLY-DISCOUNT-4YR-PERIOD',
  'YEARLY-DISCOUNT-5YR-PERIOD' = 'YEARLY-DISCOUNT-5YR-PERIOD',
}

export default CouponCodeType

/** Zod schema for coupon_code_type_ */
export const couponCodeType = z.enum([
  'SPONSORED-SUBSCRIPTION',
  'YEARLY-DISCOUNT-1YR-PERIOD',
  'YEARLY-DISCOUNT-2YR-PERIOD',
  'YEARLY-DISCOUNT-3YR-PERIOD',
  'YEARLY-DISCOUNT-4YR-PERIOD',
  'YEARLY-DISCOUNT-5YR-PERIOD',
])
