import { Text } from '@chakra-ui/react'
import fastJsonPatch from 'fast-json-patch'
import type { FormikValues } from 'formik'
import merge from 'lodash.merge'

import { site } from 'api'

import {
  useLoggerSelectOptions,
  useCableSelectOptions,
  filterLoggersByProjectId,
  filterCablesByProjectId,
} from 'hooks/useSelectOptions'

import { siteSchema } from 'lib/jsValidate'
import { logValidationErrors } from 'lib/logValidationErrors'
import { parseDecodeOrder } from 'lib/parseDecodeOrder'
import { queryClient } from 'lib/react-query'
import { setDemoSensorLabel } from 'lib/setDemoSensorLabel'
import { validateDate } from 'lib/validateDate'

import type { FormInfo, CreateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

const ignoreKeys = ['/extras', '/perm', '/mongoExtras', '/_id']

export const updateCustomerSiteForm = ({
  initialValues,
}: CreateFormProps = {}): FormInfo<FormikValues> => ({
  fields: [
    {
      name       : 'name',
      type       : 'text',
      label      : 'Site Name',
      placeholder: 'Site Name',
      isRequired : true,
      validate   : (value: any) => {
        if (!value) return 'Site name is required'
      },
    },

    {
      name       : 'details',
      label      : 'Details',
      type       : 'textarea',
      placeholder: 'Enter a description or details here...',
    },

    {
      title       : 'Location',
      titleColSpan: 2,
      type        : 'group',
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          name : 'geo.lat',
          type : 'text',
          label: 'Latitude',
        },
        {
          name : 'geo.lon',
          type : 'text',
          label: 'Longitude',
        },
      ],
    },

    {
      title       : 'Snow Depth',
      titleColSpan: 2,
      type        : 'group',
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          name : 'sensor.height',
          type : 'number',
          label: 'Sonic Sensor Height (cm)',
        },
        {
          name         : 'display.plot_snow',
          type         : 'checkbox',
          label        : 'Plot Snow',
          gridItemProps: {
            justifySelf: 'center',
            alignSelf  : 'center',
          },
        },
      ],
    },

    {
      type        : 'group',
      title       : 'Label Settings',
      titleColSpan: 3,
      gridProps   : {
        templateColumns: '1fr 1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          label  : 'Units',
          name   : 'data.units.length',
          type   : 'radio',
          options: [
            { value: 'in', name: 'in' },
            { value: 'ft', name: 'ft' },
            { value: 'm', name: 'm' },
            { value: 'cm', name: 'cm' },
          ],
          onChange     : setDemoSensorLabel,
          gridItemProps: {
            paddingBottom: 3,
          },
        },
        {
          label  : 'Sort Order',
          name   : 'labelSettings.sortBy',
          type   : 'radio',
          options: [
            { value: 'serial', name: 'Serial' },
            { value: 'depth', name: 'Depth' },
          ],
          tooltip: 'Sort labels by serial number or depth',
        },
        {
          label        : 'Show Serials',
          name         : 'labelSettings.showCableSerials',
          type         : 'checkbox',
          gridItemProps: {
            paddingTop: '32px',
          },
          tooltip: 'Show cable serials in line labels',
        },
      ],
    },

    {
      title  : 'Logger History',
      type   : 'array',
      name   : 'use.logger',
      newItem: () => ({
        imei : '',
        begin: '',
      }),
      newItemText: 'Add Logger',
      removeItem : true,
      gridProps  : {
        templateColumns: '1fr 1fr 1fr auto',
        gap            : 3,
      },
      headers: ['Logger', 'Begin Date', 'End Date', ''],
      fields : [
        {
          name               : 'imei',
          type               : 'select',
          label              : '',
          placeholder        : 'Select Logger',
          useSelectOptions   : useLoggerSelectOptions,
          filter             : filterLoggersByProjectId,
          useValueIfNoOptions: true,
          keyPrefix          : 'logger-imei-',
          style              : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          shouldBeReadOnly: (values: any, index: number, arrlen: number) => {
            if (
              initialValues?.use?.logger?.[index]?.imei &&
              initialValues?.use?.logger?.[index]?.begin &&
              initialValues?.use?.logger?.[index]?.end
            ) {
              return true
            }
            return false
          },
        },
        {
          name       : 'begin',
          type       : 'mask',
          label      : '',
          validate   : validateDate,
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          shouldBeReadOnly: (values: any, index: number, arrlen: number) => {
            if (
              initialValues?.use?.logger?.[index]?.imei &&
              initialValues?.use?.logger?.[index]?.begin &&
              initialValues?.use?.logger?.[index]?.end
            ) {
              return true
            }
            return false
          },
        },
        {
          name       : 'end',
          type       : 'mask',
          label      : '',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          validate   : validateDate,
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          shouldBeReadOnly: (values: any, index: number, arrlen: number) => {
            if (
              initialValues?.use?.logger?.[index]?.imei &&
              initialValues?.use?.logger?.[index]?.begin &&
              initialValues?.use?.logger?.[index]?.end
            ) {
              return true
            }
            return false
          },
        },
      ],
    },

    {
      title  : 'Cable History',
      type   : 'array',
      name   : 'use.cable',
      newItem: () => ({
        serial: 0,
        begin : '',
      }),
      newItemText: 'Add Cable',
      removeItem : true,
      gridProps  : {
        templateColumns: '1fr 1fr 1fr auto',
        gap            : 3,
      },
      headers: ['Cable', 'Begin Date', 'End Date', ''],
      fields : [
        {
          name               : 'serial',
          type               : 'select',
          label              : '',
          placeholder        : 'Select Cable',
          useSelectOptions   : useCableSelectOptions,
          filter             : filterCablesByProjectId,
          keyPrefix          : 'cable-serial-',
          useValueIfNoOptions: true,
          shouldBeReadOnly   : (values: any, index: number, arrlen: number) => {
            if (
              initialValues?.use?.cable?.[index]?.serial &&
              initialValues?.use?.cable?.[index]?.begin &&
              initialValues?.use?.cable?.[index]?.end
            ) {
              return true
            }
            return false
          },
        },
        {
          name       : 'begin',
          type       : 'mask',
          label      : '',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          validate        : validateDate,
          shouldBeReadOnly: (values: any, index: number, arrlen: number) => {
            if (
              initialValues?.use?.cable?.[index]?.serial &&
              initialValues?.use?.cable?.[index]?.begin &&
              initialValues?.use?.cable?.[index]?.end
            ) {
              return true
            }
            return false
          },
        },
        {
          name       : 'end',
          type       : 'mask',
          label      : '',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          validate        : validateDate,
          shouldBeReadOnly: (values: any, index: number, arrlen: number) => {
            if (
              initialValues?.use?.cable?.[index]?.serial &&
              initialValues?.use?.cable?.[index]?.begin &&
              initialValues?.use?.cable?.[index]?.end
            ) {
              return true
            }
            return false
          },
        },
        {
          title      : 'Cable Labels (optional)',
          description: (
            <>
              <Text>
                Set labels for the cable by adding numbers and/or text. Use the
                'Visible' checkbox to hide labels from the chart.
              </Text>
              <Text>
                Cable labels support 'fenceposting' for sensor depth, where
                entering the depth of some sensors allows automatic calculation
                of depths for the rest based on cable spacing data.
              </Text>
            </>
          ),
          type     : 'array',
          keyPrefix: 'cable-labels-',
          name     : 'labels',
          newItem  : () => ({ number: '', note: '', visible: true }),
          gridProps: {
            templateColumns: 'auto 1fr 1fr auto auto auto',
            gap            : 3,
          },
          newItemText  : 'Add Cable Label',
          gridItemProps: {
            colSpan     : 3,
            marginBottom: 3,
            border      : '1px solid rgb(226, 232, 240)',
            borderRadius: 'md',
          },
          showLineNumbers : true,
          lineNumberLabels: {
            text    : 'Sensor',
            position: 'left',
          },
          collapsible       : true,
          initiallyCollapsed: true,
          headers           : [
            '',
            'Depth',
            'Note (optional)',
            'Visible',
            'Line Label',
            '',
          ],
          fields: [
            {
              name       : 'number',
              type       : 'number',
              label      : '',
              placeholder: 'Sensor Depth',
              onChange   : setDemoSensorLabel,
              style      : {
                paddingInlineStart: 1,
                paddingInlineEnd  : 1,
              },
            },
            {
              name       : 'note',
              type       : 'text',
              label      : '',
              placeholder: 'Sensor Note',
              onChange   : setDemoSensorLabel,
              style      : {
                paddingInlineStart: 1,
                paddingInlineEnd  : 1,
              },
            },
            {
              name         : 'visible',
              type         : 'checkbox',
              label        : '',
              gridItemProps: {
                justifySelf: 'center',
                alignSelf  : 'center',
              },
              onChange: setDemoSensorLabel,
            },
            {
              name       : 'render',
              type       : 'label',
              label      : '',
              placeholder: '',
            },
          ],
        },
      ],
    },
  ],

  title: 'Edit Site',

  initialValues: merge(
    {
      name                  : '',
      status                : 'active',
      includes_archived_data: false,
      project               : {
        id: 0,
      },
      data: {
        units: {
          length: 'm',
        },
      },
      visible: true,
      display: {
        order       : 0,
        plot_snow   : false,
        plot_airtemp: false,
      },
      cable: {
        airtemp: 0,
      },
      sensor: {
        at    : 0,
        height: 0,
      },
      geo: {
        lat      : 0,
        lon      : 0,
        elevation: 0,
      },
      details: '',
      use    : {
        logger: [{ imei: '' }],
        cable : [{ serial: 0 }],
      },
      labelSettings: {
        sortBy          : 'depth',
        showCableSerials: false,
      },
      readingsVersion: 1,
    },
    initialValues,
  ),

  submitText: 'Update Site',
  submitFn  : async (values: any) => {
    for (const l of values?.use?.logger ?? []) {
      if (l?.begin) {
        l.begin = new Date(l?.begin).toISOString()
      }
      if (l?.end) {
        l.end = new Date(l?.end).toISOString()
      }
    }

    for (const c of values?.use?.cable ?? []) {
      if (c?.begin) {
        c.begin = new Date(c?.begin).toISOString()
      }
      if (c?.end) {
        c.end = new Date(c?.end).toISOString()
      }
    }

    const { value } = siteSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    const changes = fastJsonPatch
      .compare(initialValues as Object, value)
      .filter(
        (change) =>
          !(change.op === 'remove' && ignoreKeys.includes(change.path)),
      )

    const res = await site.patch(value?.id, changes)
    if (res.updated !== true) throw res

    return res
  },

  modalProps: {
    size: '3xl',
  },

  validateForm: (
    values: FormikValues,
  ): { error?: ValidationError; value: FormikValues } => {
    values.sensor.decode = parseDecodeOrder(values.sensor.decode)
    const { error } = siteSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    logValidationErrors(error)

    return { error, value: values }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error updating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries([
        'sites',
        { 'project.id': variables?.project?.id },
      ])
      queryClient.invalidateQueries(['reading', { 'site.id': variables?.id }])
      queryClient.invalidateQueries(['site', { id: `${variables?.id}` }])
      queryClient.invalidateQueries('sites')
      return data
    },
  },

  closeCondition: (res) => res?.updated === true,
})
