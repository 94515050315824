import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.maxio_product_ */
export type MaxioProductId = string & { __brand: 'MaxioProductId' }

/** Represents the table public.maxio_product_ */
export default interface MaxioProductTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<MaxioProductId, MaxioProductId | undefined, MaxioProductId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  productId: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  productName: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  productHandle: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  productPriceInCents: ColumnType<number, number, number>
}

export type MaxioProduct = Selectable<MaxioProductTable>

export type MaxioProductInitializer = Insertable<MaxioProductTable>

export type MaxioProductMutator = Updateable<MaxioProductTable>

export const maxioProductId = z.string() as unknown as z.Schema<MaxioProductId>

export const maxioProduct = z.object({
  id                 : maxioProductId,
  createdAt          : z.coerce.date().nullable().optional(),
  createdBy          : z.string().nullable().optional(),
  updatedAt          : z.coerce.date().nullable().optional(),
  updatedBy          : z.string().nullable().optional(),
  deletedAt          : z.coerce.date().nullable().optional(),
  deletedBy          : z.string().nullable().optional(),
  productId          : z.number(),
  productName        : z.string(),
  productHandle      : z.string(),
  productPriceInCents: z.number(),
}) as unknown as z.Schema<MaxioProduct>

export const maxioProductInitializer = z.object({
  id                 : maxioProductId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : z.string().optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : z.string().optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : z.string().optional().nullable().optional(),
  productId          : z.number(),
  productName        : z.string(),
  productHandle      : z.string(),
  productPriceInCents: z.number(),
}) as unknown as z.Schema<MaxioProductInitializer>

export const maxioProductMutator = z.object({
  id                 : maxioProductId.optional(),
  createdAt          : z.coerce.date().optional().nullable().optional(),
  createdBy          : z.string().optional().nullable().optional(),
  updatedAt          : z.coerce.date().optional().nullable().optional(),
  updatedBy          : z.string().optional().nullable().optional(),
  deletedAt          : z.coerce.date().optional().nullable().optional(),
  deletedBy          : z.string().optional().nullable().optional(),
  productId          : z.number().optional(),
  productName        : z.string().optional(),
  productHandle      : z.string().optional(),
  productPriceInCents: z.number().optional(),
}) as unknown as z.Schema<MaxioProductMutator>
