import fastJsonPatch from 'fast-json-patch'
import type { FormikValues } from 'formik'

import { project } from 'api'

import { projectSchema } from 'lib/jsValidate'
import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import type { FormInfo } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

interface IEditCustomerProjectFormProps {
  initialValues: FormikValues
}

const ignoreKeys = ['/extras', '/perm', '/mongoExtras', '/_id']

export const editCustomerProjectForm = ({
  initialValues,
}: IEditCustomerProjectFormProps): FormInfo<FormikValues> => ({
  fields: [
    {
      name       : 'name',
      type       : 'text',
      label      : 'Project Name',
      placeholder: 'Project Name',
      isRequired : true,
    },

    {
      type        : 'group',
      title       : 'Units',
      titleColSpan: 2,
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      fields: [
        {
          name   : 'units.temp',
          type   : 'radio',
          label  : 'Temperature',
          options: [
            { name: 'F', value: 'F' },
            { name: 'C', value: 'C' },
          ],
        },

        {
          name   : 'units.length',
          type   : 'radio',
          label  : 'Length',
          options: [
            { name: 'm', value: 'm' },
            { name: 'cm', value: 'cm' },
            { name: 'ft', value: 'ft' },
            { name: 'in', value: 'in' },
          ],
        },
      ],
    },

    {
      type       : 'image',
      name       : 'blueprint.image',
      description: 'Limited to 5mb',
      accept     : 'image/*',
      label      : 'Blueprint Image',
    },
  ],

  title: 'Edit Project',
  initialValues,

  submitText: 'Save Project',
  submitFn  : async (values: any) => {
    const { value } = projectSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    const changes = fastJsonPatch
      .compare(initialValues as Object, value)
      .filter(
        (change) =>
          !(change.op === 'remove' && ignoreKeys.includes(change.path)),
      )

    const res = await project.patch(value?.id, changes)
    if (res.updated !== true) throw res

    return res
  },

  validateForm: (
    values: FormikValues,
  ): { error?: ValidationError; value: FormikValues } => {
    if (values.ts.expired !== undefined)
      values.ts.expired = new Date(values.ts.expired).toISOString()
    const { error } = projectSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    logValidationErrors(error)

    return { error, value: values }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error creating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries(['project', { id: `${variables.id}` }])
      queryClient.invalidateQueries('projects')
      queryClient.invalidateQueries('project')
    },
  },

  closeCondition: (res) => res?.updated === true,
})
