import { Text, useBreakpointValue } from '@chakra-ui/react'
import { format } from 'date-fns'
import memoize from 'memoizee'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { GrEdit } from 'react-icons/gr'
import { Cell } from 'react-table'

import { OrganizationId, perms, ProjectWithOrganization } from '@beaded/models'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { booleanSort, DataTable } from 'components/Table'

import { useUserContext } from 'hooks/useUserContext'

import { deleteProject } from 'lib/mutations/delete/deleteProject'
import { editCustomerProjectForm } from 'lib/mutations/update/editCustomerProject'
import { updateProjectForm } from 'lib/mutations/update/updateProject'
import { validDate } from 'lib/validDate'

// import type { Project } from 'types/Project'
import type { KeysOf } from 'types/common'

type ProjectCell = Cell & {
  row: {
    original: ProjectWithOrganization
  }
}

interface ProjectsTableProps {
  data: any
  globalFilter: any
  projection: ProjectsTableProjection
  download: any
}

export const ProjectsTable = ({
  data = [],
  globalFilter,
  projection = 'default',
  download,
}: ProjectsTableProps) => {
  const zoom = useBreakpointValue({
    base: 0.9,
    md  : 1,
  })

  return (
    <>
      {data && data?.length > 0 ? (
        <DataTable
          columns={projections[projection]}
          data={data}
          initialGlobalFilter={globalFilter}
          hiddenColumns={hiddenColumns}
          hideGlobalFilter={!(data.length > 10)}
          download={download}
          downloadColumns={downloadColumns}
          sx={{
            zoom,
            mozTransform: `scale(${zoom})`,
          }}
          sortBy={[{ id: 'projectName', desc: false }]}
        />
      ) : (
        <Text>no data found</Text>
      )}
    </>
  )
}

const hiddenColumns: string[] = []
const downloadColumns = [
  'id',
  'public',
  'visible',
  'projectName',
  'organizationId',
  'organizationName',
  'extras.siteCount',
  'extras.activeSiteCount',
  'extras.retiredSiteCount',
  'extras.loggerCount',
  'extras.cableCount',
  'extras.lastLogged',
  'lengthUnits',
  'tempUnits',
]

const columns = {
  id  : { Header: 'id', accessor: 'id' },
  name: {
    Header  : 'Name',
    accessor: 'projectName',
    Cell    : ({ value, row }: ProjectCell) => (
      <InternalLink name={value} href={`/projects/${row.original.id}`} />
    ),
  },
  publicName: {
    Header  : 'Name',
    accessor: 'projectName',
    Cell    : ({ value, row }: ProjectCell) => (
      <InternalLink name={value} href={`/public/projects/${row.original.id}`} />
    ),
  },
  active: {
    Header  : 'Active',
    accessor: 'active',
    Cell    : ({ value, row }: ProjectCell) => <Text>{value ? 'active' : ''}</Text>,
  },
  lastLogged: {
    Header  : 'Last Logged',
    accessor: 'extras.lastLogged',
    Cell    : ({ value }: ProjectCell) => {
      if (value) {
        const d = validDate(value)
        if (d) return format(d, 'yyyy-MM-dd')
      }
      return null
    },
  },

  permLevel: {
    Header  : '',
    id      : 'perms',
    accessor: 'perm',
    Cell    : ({ row }: ProjectCell) => {
      const { hasPermission } = useUserContext()
      if (
        hasPermission(perms.EDIT_PROJECT, {
          projectId     : row.original.id,
          organizationId: row.original.organizationId as OrganizationId,
        })
      )
        return <GrEdit size={16} />
      return null
    },
  },

  siteCount: {
    Header  : 'Sites',
    accessor: 'extras.activeSiteCount',
  },

  visible: {
    id      : 'visible',
    accessor: 'visible',
    Cell    : ({ value }: ProjectCell) => {
      if (value === true) return <AiFillEye size={16} />
      if (!value) return <AiOutlineEyeInvisible size={16} />
    },
    sortType: booleanSort,
  },

  public: {
    Header  : 'Public',
    accessor: 'public',
    Cell    : ({ value }: ProjectCell) => (value ? 'public' : 'private'),
  },

  org: {
    Header  : 'Org',
    accessor: 'organizationName',
    Cell    : ({ value, row }: ProjectCell) => {
      const { hasPermission } = useUserContext()

      if (value !== undefined) {
        if (
          hasPermission(perms.VIEW_ORGANIZATION, {
            organizationId: row.original?.organizationId,
          })
        )
          return (
            <InternalLink
              name={value ?? row.original?.organizationId}
              href={`/orgs/${row.original?.organizationId}`}
            />
          )
        else return value
      }

      return null
    },
  },

  publicOrg: {
    Header  : 'Org',
    accessor: 'organizationName',
    Cell    : ({ value, row }: ProjectCell) => {
      if (value !== undefined)
        return (
          <InternalLink
            name={value ?? row.original?.organizationId}
            href={`/public/orgs/${row.original?.organizationId}`}
          />
        )
      return null
    },
  },

  expired: {
    Header  : 'Expires',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      if (originalRow?.ts?.expired) {
        const d = validDate(originalRow?.ts?.expired)
        if (d) return format(d, 'yyyy-MM-dd')
      }
      return null
    }),
  },

  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: ProjectCell) => {
      if (!value) return null

      return <NotesModal note={value} name={row.values?.projectName} />
    },
  },

  actions: actions({
    changelog: true,
    formInfo : updateProjectForm,
    deleteFn : deleteProject,
  }),

  customerOrgActions: actions({
    formInfo     : editCustomerProjectForm,
    buttonSize   : 'md',
    buttonVariant: 'link',
    table        : 'project',
    view         : 'org',
  }),
}

const projections = {
  default: [
    columns.visible,
    columns.id,
    columns.name,
    columns.lastLogged,
    columns.siteCount,
    columns.expired,
    columns.public,
    columns.actions,
  ],

  orgPage: [
    // columns.id,
    columns.permLevel,
    columns.name,
    columns.lastLogged,
    columns.siteCount,
    columns.expired,
    columns.customerOrgActions,
  ],

  adminOrgPage: [
    columns.visible,
    columns.id,
    columns.name,
    columns.lastLogged,
    columns.siteCount,
    columns.expired,
    columns.public,
    columns.notes,
    columns.actions,
  ],

  projectsPage: [
    // columns.id,
    columns.permLevel,
    columns.name,
    columns.lastLogged,
    columns.siteCount,
    columns.expired,
    columns.org,
    columns.actions,
  ],

  globalPage: [
    // columns.id,
    columns.permLevel,
    columns.name,
    columns.lastLogged,
    columns.siteCount,
    columns.expired,
    columns.org,
  ],

  adminGlobalPage: [
    // columns.id,
    columns.name,
    columns.lastLogged,
    columns.siteCount,
    columns.expired,
    columns.org,
    columns.actions,
  ],

  publicProjectsPage: [
    // columns.id,
    columns.publicName,
    columns.lastLogged,
    columns.siteCount,
    columns.publicOrg,
  ],
}

export type ProjectsTableProjection = KeysOf<typeof projections>
