import { z } from 'zod'

import { userId, UserId } from '@beaded/models'

export type MaxioCustomerInitilizer = {
  customer: {
    organization: string
    firstName: string
    lastName: string
    email: string
    phone: string
  }
  userId: UserId
}

export const maxioCustomerInitilizer = z.object({
  customer: z.object({
    organization: z.string().optional(),
    firstName   : z.string(),
    lastName    : z.string(),
    email       : z.string(),
    phone       : z.string().optional(),
  }),
  userId: userId,
}) as unknown as z.Schema<MaxioCustomerInitilizer>
